import toastr from 'toastr';
import ClipboardJS from 'clipboard';

window.toastr = toastr;

//Copy link function
const clipboard = new ClipboardJS('[data-clipboard-text]');
clipboard.on('success', function(e) {
    const elem = e.trigger;

    toastr.options = {
        "closeButton": false,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    }

    toastr["success"](elem.getAttribute("data-clipboard-message") || "Text copied");
});

//Block link on clipboard copy buttons
document.querySelectorAll("[data-clipboard-text]").forEach((elem) => {
    elem.addEventListener("click", (event) => {
        event.preventDefault();
    });
});
