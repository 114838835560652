//Mobile limit
window.mobile_view = "992px";

//Set of color
window.primary_color = "#FFB800";
window.secondary_color = "#39B100";
window.tertiary_color = "#0A4E09";
window.quartary_color = "#FAFAFA";
window.pentarium_color = "#F0CF65";

//Set of font colors
window.primary_font_color = "#FAFAFA";
window.secondary_font_color = secondary_color;
window.tertiary_font_color = "#222222";
